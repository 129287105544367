// TODO:// Remove eslint disable in a future
// This was added in favor to use enums but this error arise:
// <'TradeableCurrencies' is already declared in the upper scope on line 43 column 12  no-shadow
// https://stackoverflow.com/questions/63961803/eslint-says-all-enums-in-typescript-app-are-already-declared-in-the-upper-scope

// eslint-disable-next-line
enum TradeableCurrencies {
  USD = 'USD',
  DAI = 'DAI',
  MXN = 'MXN',
  ARS = 'ARS',
  BRL = 'BRL',
  ETH = 'ETH',
  BTC = 'BTC',
  LTC = 'LTC',
  BAT = 'BAT',
  TUSD = 'TUSD',
  USDT = 'USDT',
  MANA = 'MANA',
  XRP = 'XRP',
  GNT = 'GNT',
}

export const CurrencyAssets = {
  [TradeableCurrencies.USD]: 'usd',
  [TradeableCurrencies.DAI]: 'dai',
  [TradeableCurrencies.MXN]: 'mxn',
  [TradeableCurrencies.ARS]: 'ars',
  [TradeableCurrencies.BRL]: 'brl',
  [TradeableCurrencies.ETH]: 'eth',
  [TradeableCurrencies.BTC]: 'btc',
  [TradeableCurrencies.LTC]: 'ltc',
  [TradeableCurrencies.BAT]: 'bat',
  [TradeableCurrencies.TUSD]: 'tusd',
  [TradeableCurrencies.USDT]: 'usdt',
  [TradeableCurrencies.MANA]: 'mana',
  [TradeableCurrencies.XRP]: 'xrp',
  [TradeableCurrencies.GNT]: 'gnt',
};

export const AvailableMinors = {
  [TradeableCurrencies.USD]: CurrencyAssets.USD,
  [TradeableCurrencies.DAI]: CurrencyAssets.DAI,
  [TradeableCurrencies.USDT]: CurrencyAssets.USDT,
  [TradeableCurrencies.MXN]: CurrencyAssets.MXN,
  [TradeableCurrencies.ARS]: CurrencyAssets.ARS,
  [TradeableCurrencies.BRL]: CurrencyAssets.BRL,
};

// eslint-disable-next-line
enum Trades {
  BUY = 'BUY',
  SELL = 'SELL',
}

interface tradeTypesInterface extends Record<string, unknown> {
  [Trades.BUY]: 'buy';
  [Trades.SELL]: 'sell';
}

export const TradeTypes: tradeTypesInterface = {
  [Trades.BUY]: 'buy',
  [Trades.SELL]: 'sell',
};

// eslint-disable-next-line
enum Orders {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
  STOPLIMIT = 'STOPLIMIT',
  STOPLOSS = 'STOPLOSS',
}

export const OrderTypes = {
  [Orders.LIMIT]: 'limit',
  [Orders.MARKET]: 'market',
  [Orders.STOPLIMIT]: 'stopLimit',
  [Orders.STOPLOSS]: 'stopLoss',
};
