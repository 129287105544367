// Libraries
import { find } from 'lodash';
import BigNumber from 'bignumber.js';
import BitsoNumber from '@legacy/libraries/BitsoNumber';

// Constants
import { AvailableMinors, CurrencyAssets } from '@legacy/pages/AlphaClassic/appConstants';

// Helpers
import { formatPricePrecision } from './formatters';

//  @ts-ignore
import { Metadata as CurrencyMetadataType } from '@legacy/containers/UserContainer/types';
//  @ts-ignore
import { MarketDryRunReturn, Order } from '@legacy/containers/OrdersContainer/types';
import AvailableBooksType from '../context/types/availableBooks';
import TickerType from '../context/types/ticker';
import { MarketSchemeType, MetadataArrayType, OHLCType } from './types';

const { USD, BTC } = CurrencyAssets;
const DEFAULT_TICK_SIZE = '0.01';

/**
 * Get the currency metadata sending the metadata collection and currency key
 * @param metadata Metadata object that contains useful information from the currencies listed
 * @param currency Currency code string to find the metadata object
 * @returns Currency metadata from the collection with the key currency to identify it
 */
export const getCurrencyMetadata = (metadata: MetadataArrayType, currency: string): CurrencyMetadataType =>
  find(metadata, { code: currency }) as CurrencyMetadataType;

/**
 * Higher-order function to generate a currency metadata function that hoists the metadata object
 * to avoid re-defining the variable every call
 * @param metadata Metadata object that contains useful information from the currencies listed
 * @returns Function to get currency metadata
 */
export const generateGetCurrencyMetadata = (
  metadata: MetadataArrayType,
): ((currency: string) => CurrencyMetadataType) => {
  if (!metadata)
    return () => ({
      code: '',
      full_name: '',
      color: '',
      precision: 2,
      type: '',
      iba_terms: [],
    });
  return (currency: string): CurrencyMetadataType => find(metadata, { code: currency }) as CurrencyMetadataType;
};

/**
 * Get the currency precision number to check how many numbers to show after decimal point
 * @param currencyMetadata Object that defines the metadata info from a currency with useful information
 * @returns Currency precision number to check how many numbers to show after decimal point
 */
export const getCurrencyPrecision = (currencyMetadata: CurrencyMetadataType): number => {
  const { precision } = currencyMetadata || { precision: 2 };
  return precision;
};

/**
 * Get some useful info from the OHLC array like the open and close values of the last data point
 * @param ohlc Array of OHLC data for the candle chart
 * @returns Open and close value of the last data point of the OHLC
 */
export const getMarketStatsFromOHLC = (ohlc: OHLCType): { open: number; close: number } => {
  if (ohlc.length === 0) return { open: 0, close: 0 };
  const { first_rate: open, last_rate: close } = ohlc[ohlc.length - 1];

  return { open: Number(open), close: Number(close) };
};

/**
 * Get the high, low and volume of the market based on the ticker payload data
 * @param ticker Ticker data that shows market info from the last 24hrs
 * @param major String code of the market major
 * @param minor String code of the market minor
 * @returns High, low and volume of the market
 */
export const getMarketStatsFromTicker = (
  ticker: TickerType,
  major = CurrencyAssets.BTC,
  minor = CurrencyAssets.MXN,
): { low: number; high: number; volume: number } => {
  const { low, high, volume } = ticker.find(t => t.book === `${major}_${minor}`) || {};

  return { low: Number(low), high: Number(high), volume: Number(volume) };
};

/**
 * Get the last value of the market based on the ticker payload data
 * @param ticker Ticker data that shows market info from the last 24hrs
 * @param major String code of the market major
 * @param minor String code of the market minor
 * @returns Last price of the market

 */
export const getLastPriceFromTicker = (
  ticker: TickerType,
  major = CurrencyAssets.BTC,
  minor = CurrencyAssets.MXN,
): number | 0 => {
  const { last } = ticker.find(t => t.book === `${major}_${minor}`) || {};
  return Number(last) || 0;
};

/**
 * Get the market scheme of the available markets to show in the Market Selector depending on
 * the user's preferred currency
 * @param preferredCurrency String code of the user preferred currency
 * @returns An array of arrays that define the order to show the data in the Market Selector
 */
export const getMarketScheme = (preferredCurrency: string): Array<MarketSchemeType> => {
  const marketScheme = [
    [{ minor: preferredCurrency }],
    [preferredCurrency === BTC ? { minor: USD } : { minor: BTC }],
    ...Object.values(AvailableMinors)
      .filter(
        minor =>
          (preferredCurrency === BTC && minor !== BTC && minor !== USD) ||
          (preferredCurrency !== BTC && minor !== preferredCurrency),
      )
      .map(minor => [{ minor }]),
  ];

  return marketScheme;
};

/**
 * Takes the available books data to get the tick_size property of the selected book to get the actual
 * book precision to use
 * @param availableBooks Available books data payload
 * @param major String code of the market major
 * @param minor String code of the market minor
 * @returns The actual book precision to use
 */
export const getBookPrecision = (
  availableBooks: AvailableBooksType,
  major = CurrencyAssets.BTC,
  minor = CurrencyAssets.MXN,
): number => {
  const book = availableBooks.find(availableBook => availableBook.book === `${major}_${minor}`);
  const tickSize = book ? book.tick_size : DEFAULT_TICK_SIZE;
  return formatPricePrecision(tickSize);
};

/**
 * Takes the available books data to get the tick_size property of the selected book
 * @param availableBooks Available books data payload
 * @param major String code of the market major
 * @param minor String code of the market minor
 * @returns The actual book tick size
 */
export const getBookTickSize = (
  availableBooks: AvailableBooksType,
  major = CurrencyAssets.BTC,
  minor = CurrencyAssets.MXN,
): number => {
  const book = availableBooks.find(availableBook => availableBook.book === `${major}_${minor}`);
  return Number(book ? book.tick_size : DEFAULT_TICK_SIZE);
};

/**
 * Takes the available books data to get an object with the book precision
 * @param availableBooks Available books data payload
 * @param major String code of the market major
 * @param minor String code of the market minor
 * @returns The book object with the pricePrecision property
 */
export const generateGetBook =
  (availableBooks: AvailableBooksType) =>
  (major: string, minor: string): { pricePrecision: number } => {
    const book = availableBooks.find(availableBook => availableBook.book === `${major}_${minor}`);
    const tickSize = book ? book.tick_size : DEFAULT_TICK_SIZE;
    return {
      pricePrecision: formatPricePrecision(tickSize),
    };
  };

/**
 * Generate the convert function to move a currency value to another currency
 * @param rates Rates object with the value of each currency from MXN
 * @returns A function to convert currencies
 */
export const generateConvert =
  (rates: { [key: string]: string }) =>
  (from: string, to: string, amount: string | number | BigNumber): typeof BitsoNumber => {
    //  @ts-ignore
    if (from === to) return BitsoNumber(amount);
    const exchangeRate = BitsoNumber(rates[to]);

    //  @ts-ignore
    return BitsoNumber(amount).dividedBy(exchangeRate);
  };

/**
 * Get the mobile operating system name either android, ios or unknown
 * @returns The name of the mobile operating system
 */
export const getMobileOperatingSystem = (): 'android' | 'ios' | 'unknown' => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'ios';
  }

  return 'unknown';
};

/**
 * Get the best price for an order book side, either buy or sell
 * @param orders Orders collection asks/bids
 * @returns The value of the best offer of ask or bid
 */
export const getBestOrderPrice = (orders: Map<string, Order>): string => {
  const bestAskOrder = orders.values().next();
  return !bestAskOrder.done ? bestAskOrder.value.price : '0';
};

/**
 * Generate the dry run function in an order book
 * @param orders Orders collection asks/bids
 * @returns An object with the order book metadata after the dry run
 */
export const generateMakeDryRun =
  (orders: { asks: Map<string, Order>; bids: Map<string, Order> }) =>
  (major: string, amount: number, amountCurrency: string, side: string): MarketDryRunReturn | null => {
    const { asks, bids } = orders;

    const matched: Array<Order> = [];
    const bAmount = BitsoNumber(amount);
    if (!bAmount.isFinite()) return null;
    let filled = BitsoNumber(0);
    let exchanged = BitsoNumber(0);
    let pricesSum = BitsoNumber(0);

    const activeSide = side === 'buy' ? asks : bids;
    const sideValues = activeSide.values();
    let { value: order, done } = sideValues.next();

    while (filled.lt(bAmount) && !done) {
      const { amount: orderAmount, price: orderPrice } = order;
      const bOrderAmount = BitsoNumber(orderAmount);
      const bOrderPrice = BitsoNumber(orderPrice);
      const orderValue = BitsoNumber(bOrderAmount).times(bOrderPrice);
      pricesSum = pricesSum.plus(bOrderPrice);
      matched.push(order);

      if (amountCurrency === major) {
        const amountToTake = bAmount.minus(filled).gt(bOrderAmount) ? bOrderAmount : bAmount.minus(filled);
        filled = filled.plus(amountToTake);
        exchanged = exchanged.plus(amountToTake.times(bOrderPrice));
      } else {
        const valueToTake = bAmount.minus(filled).gte(orderValue) ? orderValue : bAmount.minus(filled);
        filled = filled.plus(valueToTake);
        exchanged = exchanged.plus(valueToTake.dividedBy(bOrderPrice));
      }

      ({ value: order, done } = sideValues.next());
    }

    if (!matched.length) return null;

    const averagePrice = pricesSum.dividedBy(matched.length);
    const slippagePercent = averagePrice.minus(matched[0].price).abs().dividedBy(matched[0].price).times(100);
    const [finalAmount, finalValue] = amountCurrency === major ? [filled, exchanged] : [exchanged, filled];

    return {
      amount: finalAmount,
      matched,
      slippagePercent,
      value: finalValue,
      averagePrice,
    };
  };

/**
 * A function that indicates if the book is available in the available books list
 * @param availableBooks Available books data payload
 * @param major String code of the market major
 * @param minor String code of the market minor
 * @returns A boolean indicating if the book is available in the available book list
 */
export const isBookAvailable = (availableBooks: AvailableBooksType, major: string, minor: string): boolean =>
  availableBooks.some(book => book.book === `${major}_${minor}`);
