// Libraries
import { merge, map } from 'lodash';
import BitsoNumber from '@legacy/libraries/BitsoNumber';

// @ts-ignore
import { OutletContextProps } from '@legacy/pages/WalletCurrencyDetailsWrapper/types';
// import { Trade as UserTrade } from '@legacy/containers/UserContainer/types';
import { OrdersType } from '../context/types/orders';
import { UserOrdersType } from '../context/types/userOrders';
import AccountStatusType from '../context/types/accountStatus';
import AvailableBooksType from '../context/types/availableBooks';
import CataloguesType from '../context/types/catalogues';
import CombinedBalancesType from '../context/types/combinedBalances';
import TickerType from '../context/types/ticker';
import TradesType from '../context/types/trades';
import UserSettingsType from '../context/types/userSettings';
import UserTradesType from '../context/types/userTrades';

// Helpers
import { generateGetCurrencyMetadata } from './getters';
import {
  BookType,
  FormattedUserBalances,
  FormattedUserOrders,
  MarketSchemeType,
  UserType,
} from './types';
import { BooksModel, Trade } from '@components/TradesTable/TradesTable';
import { Order } from '@migrated/context/types/orders';
// @ts-ignore
import { Trade as UserTrade } from '@legacy/containers/UserContainer/types';
// import { Trade } from '@components/TradesTable/TradesTable';

/**
 * Format book models from the ticker payload information
 * @param ticker Ticker data that shows market info from the last 24hrs
 * @returns Book model information coming from the ticker payload
 */
export const formatBooksFromTicker = (ticker: TickerType): BooksModel => {
  const booksFromTicker = ticker.reduce((prevVal, tickerItem) => {
    const { book, ...restOfData } = tickerItem;
    const [major, minor] = book.split('_');

    return {
      ...prevVal,
      [minor]: {
        ...prevVal[minor],
        [major]: restOfData,
      },
    };
  }, {});

  return booksFromTicker;
};

/**
 * Format book models from the ticker payload information
 * @param availableBooks Available books data payload
 * @returns Book model information coming from the available books payload
 */
export const formatBooksFromAvailableBooks = (availableBooks: AvailableBooksType): BooksModel => {
  const booksFromAvailableBooks = availableBooks.reduce((prevVal, bookItem) => {
    const { book, ...restOfData } = bookItem;
    const [major, minor] = book.split('_');

    return {
      ...prevVal,
      [minor]: {
        ...prevVal[minor],
        [major]: restOfData,
      },
    };
  }, {});

  return booksFromAvailableBooks;
};

/**
 * Format user profile information combined from the account status payload and the user settings
 * payload
 * @param accountStatus User information about their profile and personal info
 * @param settings User information about the settings they have setup
 * @returns The combination of the account status and settings information as the user profile
 */
export const formatUserFromAccountStatusAndSettings = (
  accountStatus: AccountStatusType,
  settings: UserSettingsType,
): UserType => {
  const user = {
    settings,
    country_of_incorporation: accountStatus.country_of_residence,
    ...accountStatus,
  };

  return user;
};

/**
 * Format the price precision to display
 * @param tickPriceString tickPrice to format the precision
 * @returns The Price precision to show the current markets for the Market Selector component
 */
export const formatPricePrecision = (tickPriceString: string): number => {
  if (!tickPriceString) {
    return 0;
  }

  try {
    const tickSize = Number(tickPriceString);
    if (tickSize < 0 || tickSize.toFixed(0) === tickPriceString) return 0;
    return (
      tickPriceString
        .split('.')[1]
        .split('')
        .findIndex(char => char !== '0') + 1
    );
  } catch {
    return 0;
  }
};

/**
 * Format the books information to be compatible with the MarketSelector component to show
 * @param marketScheme Markets scheme information to get the books arrange
 * @param books Books information formatted
 * @param getCurrencyMetadata get currency metadata function to get display info
 * @returns The necessary information to show the current markets for the Market Selector component
 */
export const formatBooksForMarketSelector = (
  marketScheme: Array<MarketSchemeType>,
  books: BooksModel,
  getCurrencyMetadata: (value: string) => void,
): Array<BookType> =>
  map(marketScheme, minors =>
    map(
      minors,
      (minor: BitsoGlobals.MissingType) =>
        ({
          majors: map(books[minor.minor], (major, majorName) => ({
            name: majorName,
            value: major.last,
            metadata: getCurrencyMetadata(majorName),
            pricePrecision: formatPricePrecision(major.tick_size),
          })),
          minor: minor.minor,
          canCollapse: minor.canCollapse,
          metadata: getCurrencyMetadata(minor.minor),
        } as BitsoGlobals.MissingType),
    ),
  );

/**
 * Get the merged books information from formatBooksFromAvailableBooks and formatBooksFromTicker functions
 * @param availableBooks Available books data payload
 * @param ticker Ticker data that shows market info from the last 24hrs
 * @returns The combined information of the books from the Available Books and the Ticker Payloads
 */
export const formatBooksFromAvailableBooksAndTicker = (
  availableBooks: AvailableBooksType,
  ticker: TickerType,
): BooksModel => merge({}, formatBooksFromAvailableBooks(availableBooks), formatBooksFromTicker(ticker));

/**
 * Format orders payload to orders book format compatible with the order books section
 * @param orders Order payload with data of the current order book
 * @param major String of the major book currency
 * @param minor String of the minor book currency
 * @param limit Limit of max amount of orders to format
 * @returns The formatted list of orders
 */
export const formatOrdersToOrderBook = (
  orders: {
    asks: Array<Order>;
    bids: Array<Order>;
  },
  major: string,
  minor: string,
  limit?: number,
): OrdersType => {
  const timestamp = new Date().getTime();

  const parsedAsks = new Map();
  const parsedBids = new Map();

  const limitedAsks = limit ? orders.asks.slice(0, limit) : orders.asks;
  const limitedBids = limit ? orders.bids.slice(0, limit) : orders.bids;

  limitedAsks.forEach(({ price, amount, oid }) => {
    parsedAsks.set(oid, {
      amount,
      major,
      minor,
      oid,
      price: Number(price),
      status: null,
      timestamp,
    });
  });

  limitedBids.forEach(({ price, amount, oid }) => {
    parsedBids.set(oid, {
      amount,
      major,
      minor,
      oid,
      price: Number(price),
      status: null,
      timestamp,
    });
  });

  return {
    asks: parsedAsks,
    bids: parsedBids,
  };
};

/**
 * Format the user orders payload to orders book format compatible with the order books section
 * @param orders User order payload with data of the current order book
 * @returns The formatted list of orders
 */
export const formatUserOrdersToOrderBook = (orders: UserOrdersType): FormattedUserOrders => {
  const openOrders = {};

  orders.forEach(order => {
    const {
      book,
      created_at: createdAt,
      oid,
      original_amount: originalAmount,
      original_value: originalValue,
      triggered_at: triggeredAt,
      unfilled_amount: unfilledAmount,
      updated_at: updatedAt,
      stop,
      ...rest
    } = order;

    const [major, minor] = book.split('_');
    if (openOrders[minor]) {
      if (!openOrders[minor][major]) {
        openOrders[minor][major] = new Map();
      }
    } else {
      openOrders[minor] = {};
      openOrders[minor][major] = new Map();
    }

    openOrders[minor][major].set(oid, {
      createdAt,
      major,
      minor,
      oid,
      originalAmount,
      originalValue,
      triggeredAt,
      unfilledAmount,
      updatedAt,
      stop: triggeredAt ? null : stop,
      ...rest,
    });
  });

  return openOrders;
};

/**
 * Format the trades payload to be compatible with the current trades section
 * @param trades Trades list of the current state of the market
 * @returns The formatted list of trades
 */
export const formatTrades = (trades: TradesType): Array<Trade> =>
  trades.map(({ created_at: createdAt, maker_side: side, tid, ...rest }) => ({
    createdAt,
    side: side === 'sell' ? 'buy' : 'sell',
    tid,
    ...rest,
  }));

/**
 * Format the user trades payload to be compatible with the current user trades section
 * @param trades User trades list of the current state of the market
 * @returns The formatted list of user trades
 */
export const formatUserTrades = (trades: UserTradesType): Array<UserTrade> =>
  trades.map(trade => {
    const {
      book,
      created_at: createdAt,
      fees_amount: feesAmount,
      fees_currency: feesCurrency,
      major_currency: majorCurrency,
      major: majorAmount,
      minor_currency: minorCurrency,
      minor: minorAmount,
      oid,
      price,
      side,
      tid,
    } = trade;

    return {
      amount: BitsoNumber(majorAmount).abs(),
      book,
      createdAt,
      feesAmount,
      feesCurrency,
      major: majorCurrency,
      majorAmount,
      minor: minorCurrency,
      minorAmount,
      oid,
      price,
      side,
      tid,
    };
  });

/**
 * Format the user balance value from different currencies to get the total amount of funds to show
 * @param balances Balances information payload with the current status of the user funds
 * @returns Formatted object with the balance per currency
 */
export const formatUserBalances = (balances: CombinedBalancesType | null): FormattedUserBalances => {
  if (!balances || !balances.balances) return {};

  return balances.balances.reduce((acc, balance) => {
    acc[balance.currency_code] = {
      available: balance.available_amount,
      currency: balance.currency_code,
      locked: BitsoNumber(balance.total_amount).minus(balance.available_amount).toString(),
      // TODO: Get correct values for pending_deposit and pending_withdrawal
      pending_deposit: '0.00000000',
      pending_withdrawal: '0.00000000',
      total: balance.total_amount,
      percent: balance.portfolio_percentage,
      converted: balance.converted_amount || balance.total_amount,
      iba: balance.iba,
    };
    return acc;
  }, {});
};

/**
 * Format an array of balances to show in the wallet page
 * @param balances Balances information payload with the current status of the user fun
 * @param catalogues Catalogues object that contains currencies metadata info
 * @returns Formatted array of balances to show in the wallet page
 */
export const formatCombinedBalancesForWallet = (
  balances: FormattedUserBalances,
  catalogues: CataloguesType,
): OutletContextProps['balances'] => {
  const getCurrencyMetadata = generateGetCurrencyMetadata(catalogues.currencies.metadata);
  const balancesKeys = Object.keys(balances);

  return balancesKeys.map(currency => ({
    lastPrice: { currency: '', price: '0.00', formatted_price: '0.00' },
    ui: [],
    priceChange: {
      percentage: '0.00',
      label: '24 h',
    },
    categories: ['all'],
    allowedAsFavorite: !catalogues.currencies.favorite_not_allowed.includes(currency),
    ...balances[currency],
    ...getCurrencyMetadata(currency),
  }));
};
